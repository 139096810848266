<template>
  <div class="overflow-x-hidden absolute -inset-4 bg-white">
    <iframe class="block border-0" style="width: 100%; height: 100%" :src="url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    url() {
      return this.parseUrlParam(this.$route.meta.url)
    },
  },
  methods: {
    // 解析url https://www.aaa.com?token=${accessToken}; 暂时只解析了${accessToken}为用户accessToken，其他按需添加即可
    parseUrlParam(url = '', params = ['accessToken']) {
      let res = url
      params.forEach((param) => {
        if (param === 'accessToken') {
          res = res.replace('${' + param + '}', this.$store.state.user.auth.accessToken)
        }
      })
      return res
    },
  },
}
</script>

<style></style>
